import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Chip } from 'primereact/chip';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Clock } from 'lucide-react';

interface TimeSlotSelectorProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = ({ value, onChange, error }) => {
  const [slots, setSlots] = useState<string[]>([]);
  const [newSlot, setNewSlot] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);

  // Initialize slots from comma-separated input
  useEffect(() => {
    if (value) {
      setSlots(value.split(',').map(slot => slot.trim()).filter(Boolean));
    } else {
      setSlots([]);
    }
  }, [value]);

  // Update parent component when slots change
  const updateParent = (updatedSlots: string[]) => {
    const slotString = updatedSlots.join(',');
    onChange(slotString);
  };

  const addSlot = () => {
    if (newSlot && !slots.includes(newSlot)) {
      const updatedSlots = [...slots, newSlot];
      setSlots(updatedSlots);
      updateParent(updatedSlots);
      setNewSlot('');
    }
  };

  const addTimeSlot = () => {
    if (selectedTime) {
      const hours = selectedTime.getHours();
      const minutes = selectedTime.getMinutes();
      const formattedTime = `${hours}:${minutes === 0 ? '00' : minutes}`;
      
      if (!slots.includes(formattedTime)) {
        const updatedSlots = [...slots, formattedTime];
        setSlots(updatedSlots);
        updateParent(updatedSlots);
        setSelectedTime(null);
      }
    }
  };

  const removeSlot = (slotToRemove: string) => {
    console.log('Removing slot:', slotToRemove);
    const updatedSlots = slots.filter(slot => slot !== slotToRemove);
    setSlots(updatedSlots);
    updateParent(updatedSlots);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && newSlot) {
      e.preventDefault();
      addSlot();
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex flex-col">
        <div className="flex items-center gap-2 mb-2">
          <InputText
            value={newSlot}
            onChange={(e) => setNewSlot(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ajouter un créneau (ex: 14h30)"
            className={`flex-1 ${error ? 'p-invalid border-red-500' : ''}`}
          />
          <Button type="button" icon="pi pi-plus" onClick={addSlot} />
        </div>
        
{/*         <div className="flex items-center gap-2">
          <Calendar
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.value as Date)}
            timeOnly
            hourFormat="24"
            placeholder="Sélectionner l'heure"
            className="flex-1"
          />
          <Button 
            type="button" 
            icon="pi pi-clock"
            onClick={addTimeSlot}
            disabled={!selectedTime}
          />
        </div> */}
      </div>
      
      {error && <small className="p-error">{error}</small>}
      
      <div className="flex flex-wrap gap-2 mt-2">
        {slots.map((slot, index) => (
          <Chip 
            key={index} 
            label={slot} 
            removable={true}
            onRemove={() => removeSlot(slot)}
            className="bg-teal-100 cursor-pointer"
          />
        ))}
        {slots.length === 0 && (
          <div className="text-gray-400 italic flex items-center">
            <Clock className="h-4 w-4 mr-1" />
            <span>Aucun créneau horaire ajouté</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeSlotSelector;