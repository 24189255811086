import React, { useState, useEffect } from 'react';
import { Calendar, Clock, Search, Filter, Download, User, Package, Info, AlertCircle, CheckCircle, XCircle } from 'lucide-react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar as PCalendar } from 'primereact/calendar';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import ReservationDetailModal from './ReservationDetailModal';
import api from '../utils/api';

interface Reservation {
  id: string;
  code: string;
  date: string;
  time: string;
  status: string;
  service: {
    title: string;
    type: string;
  };
  customer: {
    name: string;
    email: string;
    phone: string;
  };
  persons: number;
  price: number;
  created_at: string;
}

interface PaginationInfo {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

interface StatisticsData {
  total_reservations: number;
  today_reservations: number;
  upcoming_reservations: number;
  cancelled_reservations: number;
  total_revenue: number;
  monthly_revenue: Array<{
    month: string;
    revenue: number;
  }>;
}

export default function ReservationsContent() {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<StatisticsData | null>(null);
  const [selectedReservation, setSelectedReservation] = useState<Reservation | null>(null);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  // Pagination state
  const [pagination, setPagination] = useState<PaginationInfo>({
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1
  });
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  // Filters state
  const [filters, setFilters] = useState({
    status: '',
    search: '',
    start_date: null as Date | null,
    end_date: null as Date | null,
  });

  const statuses = [
    { label: 'Tous', value: '' },
    { label: 'En attente', value: 'pending' },
    { label: 'Confirmée', value: 'confirmed' },
    { label: 'Annulée', value: 'cancelled' },
    { label: 'Complétée', value: 'completed' }
  ];

  useEffect(() => {
    fetchReservations();
    fetchStatistics();
  }, [first, rows, filters]);

  const fetchReservations = async () => {
    setLoading(true);
    try {
      const response = await api.get('/merchant/reservations', {
        params: {
          page: first / rows + 1,
          per_page: rows,
          status: filters.status,
          search: filters.search,
          start_date: filters.start_date?.toISOString().split('T')[0],
          end_date: filters.end_date?.toISOString().split('T')[0],
        },
      });
      setReservations(response.data.reservations);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Failed to fetch reservations:', error);
      setReservations([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatistics = async () => {
    try {
      const response = await api.get('/merchant/reservations-statistics');
      setStatistics(response.data.statistics);
    } catch (error) {
      console.error('Failed to fetch statistics:', error);
    }
  };

  const onPageChange = (event: { first: number; rows: number }) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleFilterChange = (name: string, value: string | Date | null) => {
    setFilters(prev => ({ ...prev, [name]: value }));
    setFirst(0);
  };

  const handleReservationClick = async (reservation: Reservation) => {
    setSelectedReservation(reservation);
    setDetailModalVisible(true);
  };

  const handleExportReservations = async () => {
    setExportLoading(true);
    try {
      const response = await api.get('/merchant/export-reservations', {
        params: {
          status: filters.status,
          start_date: filters.start_date?.toISOString().split('T')[0],
          end_date: filters.end_date?.toISOString().split('T')[0],
        },
        responseType: 'blob',
      });
      
      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `reservations_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Failed to export reservations:', error);
    } finally {
      setExportLoading(false);
    }
  };

  const updateReservationStatus = async (id: string, status: string) => {
    setStatusUpdateLoading(true);
    try {
      await api.put(`/merchant/reservations/${id}/status`, { status });
      fetchReservations();
      fetchStatistics();
      setDetailModalVisible(false);
    } catch (error) {
      console.error('Failed to update reservation status:', error);
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  const getStatusSeverity = (status: string) => {
    switch (status.toLowerCase()) {
      case 'confirmed':
        return 'success';
      case 'pending':
        return 'warning';
      case 'cancelled':
        return 'danger';
      case 'completed':
        return 'info';
      default:
        return 'secondary';
    }
  };

  const statusBodyTemplate = (rowData: Reservation) => {
    return <Tag value={rowData.status} severity={getStatusSeverity(rowData.status)} />;
  };

  const actionBodyTemplate = (rowData: Reservation) => {
    return (
      <div className="flex gap-2">
        <Button 
          icon="pi pi-eye" 
          rounded 
          outlined 
          className="p-button-sm" 
          onClick={() => handleReservationClick(rowData)}
        />
      </div>
    );
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('fr-MA', { style: 'currency', currency: 'MAD' }).format(value);
  };

  const dateBodyTemplate = (rowData: Reservation) => {
    return (
      <div className="flex flex-col">
        <span>{rowData.date}</span>
        {rowData.time && <span className="text-xs text-gray-500">{rowData.time}</span>}
      </div>
    );
  };

  const customerBodyTemplate = (rowData: Reservation) => {
    return (
      <div className="flex flex-col">
        <span>{rowData.customer.name}</span>
        <span className="text-xs text-gray-500">{rowData.customer.phone}</span>
      </div>
    );
  };

  const serviceBodyTemplate = (rowData: Reservation) => {
    return (
      <div className="flex flex-col">
        <span>{rowData.service.title}</span>
        <span className="text-xs text-gray-500">{rowData.service.type}</span>
      </div>
    );
  };

  return (
    <div className="p-6 flex flex-col min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Réservations</h1>
        <Button 
          label="Exporter" 
          icon="pi pi-download" 
          className="p-button-outlined" 
          loading={exportLoading}
          onClick={handleExportReservations}
        />
      </div>

      {statistics && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div className="bg-white rounded-lg shadow p-4 flex flex-col">
            <div className="text-gray-500 mb-2 text-sm">Total des réservations</div>
            <div className="text-2xl font-bold">{statistics.total_reservations}</div>
          </div>
          <div className="bg-white rounded-lg shadow p-4 flex flex-col">
            <div className="text-gray-500 mb-2 text-sm">Réservations aujourd'hui</div>
            <div className="text-2xl font-bold">{statistics.today_reservations}</div>
          </div>
          <div className="bg-white rounded-lg shadow p-4 flex flex-col">
            <div className="text-gray-500 mb-2 text-sm">Réservations à venir</div>
            <div className="text-2xl font-bold">{statistics.upcoming_reservations}</div>
          </div>
          <div className="bg-white rounded-lg shadow p-4 flex flex-col">
            <div className="text-gray-500 mb-2 text-sm">Revenue total</div>
            <div className="text-2xl font-bold">{formatCurrency(statistics.total_revenue)}</div>
          </div>
        </div>
      )}

      <div className="flex flex-wrap gap-4 mb-6">
        <div className="p-inputgroup w-full md:w-auto">
          <span className="p-inputgroup-addon">
            <Search className="h-4 w-4" />
          </span>
          <InputText 
            placeholder="Rechercher..."
            value={filters.search}
            onChange={(e) => handleFilterChange('search', e.target.value)}
          />
        </div>
        
        <div className="p-inputgroup w-full md:w-auto">
          <span className="p-inputgroup-addon">
            <Filter className="h-4 w-4" />
          </span>
          <Dropdown 
            value={filters.status}
            options={statuses}
            onChange={(e) => handleFilterChange('status', e.value)}
            placeholder="Statut"
            className="w-full md:w-auto"
          />
        </div>
        
        <div className="p-inputgroup w-full md:w-auto">
          <span className="p-inputgroup-addon">
            <Calendar className="h-4 w-4" />
          </span>
          <PCalendar
            value={filters.start_date}
            onChange={(e) => handleFilterChange('start_date', e.value as Date)}
            placeholder="Date de début"
            showIcon
            className="w-full md:w-auto"
          />
        </div>
        
        <div className="p-inputgroup w-full md:w-auto">
          <span className="p-inputgroup-addon">
            <Calendar className="h-4 w-4" />
          </span>
          <PCalendar
            value={filters.end_date}
            onChange={(e) => handleFilterChange('end_date', e.value as Date)}
            placeholder="Date de fin"
            showIcon
            className="w-full md:w-auto"
          />
        </div>
      </div>

      <div className="flex-grow bg-white rounded-lg shadow mb-6">
        <DataTable 
          value={reservations} 
          loading={loading}
          emptyMessage="Aucune réservation trouvée"
          className="p-datatable-sm"
          stripedRows
          responsiveLayout="scroll"
        >
          <Column field="code" header="Code" sortable style={{ width: '8%' }} />
          <Column field="date" header="Date" body={dateBodyTemplate} sortable style={{ width: '15%' }} />
          <Column field="customer" header="Client" body={customerBodyTemplate} style={{ width: '20%' }} />
          <Column field="service" header="Service" body={serviceBodyTemplate} style={{ width: '25%' }} />
          <Column field="persons" header="Pers." sortable style={{ width: '7%' }} />
          <Column field="price" header="Prix" sortable body={(rowData) => formatCurrency(rowData.price)} style={{ width: '10%' }} />
          <Column field="status" header="Statut" body={statusBodyTemplate} sortable style={{ width: '10%' }} />
          <Column body={actionBodyTemplate} headerClassName="w-10rem" style={{ width: '5%' }} />
        </DataTable>
      </div>

      {pagination.total > 0 && (
        <Paginator
          first={first}
          rows={rows}
          totalRecords={pagination.total}
          rowsPerPageOptions={[10, 20, 30]}
          onPageChange={onPageChange}
          className="bg-white shadow-md rounded-lg p-4"
        />
      )}

      {detailModalVisible && selectedReservation && (
        <ReservationDetailModal
          visible={detailModalVisible}
          onHide={() => setDetailModalVisible(false)}
          reservation={selectedReservation}
          onStatusUpdate={(status) => updateReservationStatus(selectedReservation.id, status)}
          loading={statusUpdateLoading}
        />
      )}
    </div>
  );
}