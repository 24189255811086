// utils/formatters.ts

/**
 * Format a number as currency (MAD)
 */
export const formatCurrency = (value: any): string => {
    return new Intl.NumberFormat('fr-MA', {
      style: 'currency',
      currency: 'MAD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };
  
  /**
   * Format a date string to a readable format
   */
  export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('fr-MA', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }).format(date);
  };
  
  /**
   * Format a percentage value
   */
  export const formatPercentage = (value: number): string => {
    return `${value.toFixed(1)}%`;
  };
  
  /**
   * Truncate text to a specific length
   */
  export const truncateText = (text: string, maxLength: number = 50): string => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };