
import DashboardLayout from '../components/DashboardLayout';
import AbonnementPremium from '../components/AbonementContent';

export default function Abonement() {
  return (
    <DashboardLayout>
        <AbonnementPremium />
    </DashboardLayout>
  );
}