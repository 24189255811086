import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../utils/api';

export interface User {
  merchant_id: number;
  email: string;
  raison_social: string;
  responsibleName: string;
  phone: string | null;
  mobile: string | null;
  isEmailVerified: boolean;
  isProfileComplete: boolean;
  isAccountValidated: boolean;
  email_verified_at: string | null;
  description: string | null;
  avatar: string | null;
  banner: string | null;
  address: string | null;
  addressSupplement: string | null;
  ice: string | null;
  rc: string | null;
  tva: string | null;
  rib: string | null;
  plan_type: 'freemium' | 'premium';
  created_at: string;
  updated_at: string;
}

interface AuthContextType {
  user: User | null;
  token: string | null;
  isValidatingToken: boolean;
  login: (token: string, user: User) => void;
  logout: () => void;
  updateUser: (userData: Partial<User>) => void;
  refreshUserData: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isValidatingToken, setIsValidatingToken] = useState(true);

  const validateToken = async (storedToken: string) => {
    try {
      console.log(storedToken);
      api.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
      const response = await api.get('/merchant/merchant-profile');
      const userData = response.data.merchant;
      setUser(userData);
      setToken(storedToken);
      localStorage.setItem('user', JSON.stringify(userData));
      return true;
    } catch (error) {
      console.error('Token validation failed:', error);
      //logout();
      return false;
    }
  };

  useEffect(() => {
    const initAuth = async () => {
      const storedToken = await localStorage.getItem('token');
      if (storedToken) {
        await validateToken(storedToken);
      }
      setIsValidatingToken(false);
    };

    initAuth();
  }, []);

  const login = (newToken: string, userData: User) => {
    setUser(userData);
    setToken(newToken);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('token', newToken);
    api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    delete api.defaults.headers.common['Authorization'];
  };

  const updateUser = (userData: Partial<User>) => {
    if (user) {
      const updatedUser = { ...user, ...userData };
      setUser(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
    }
  };

  const refreshUserData = async () => {
    if (token) {
      await validateToken(token);
    }
  };

  return (
    <AuthContext.Provider value={{ user, token, isValidatingToken, login, logout, updateUser, refreshUserData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};