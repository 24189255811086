import React, { useState, useRef } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { Toast } from 'primereact/toast';
import { useAuth } from '../contexts/AuthContext';

export default function SignUpPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [raison_social, setRaisonSocial] = useState('');
  const [responsible, setResponsible] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [verificationPin, setVerificationPin] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const { login } = useAuth();

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!raison_social.trim()) newErrors.raison_social = 'Le nom de l\'entreprise est requis';
    if (!responsible.trim()) newErrors.responsible = 'Le nom du responsable est requis';
    if (!email.trim()) newErrors.email = 'L\'adresse e-mail est requise';
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'L\'adresse e-mail est invalide';
    if (!password) newErrors.password = 'Le mot de passe est requis';
    else if (password.length < 8) newErrors.password = 'Le mot de passe doit contenir au moins 8 caractères';
    if (password !== confirmPassword) newErrors.confirmPassword = 'Les mots de passe ne correspondent pas';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await api.post(`/signup/merchant`, {
        raison_social,
        responsibleName: responsible,
        email,
        password
      });

      console.log('Signup successful', response.data);
      login(response.data.token, response.data.merchant);
      setShowVerification(true);
      toast.current?.show({severity: 'success', summary: 'Succès', detail: 'Compte créé avec succès. Veuillez vérifier votre email.'});
    } catch (error) {
      console.error('Signup failed', error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Cette adresse e-mail est déjà utilisée'});
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerification = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.post('/verify-email', { email, pin: verificationPin });
      console.log('Email verification successful', response.data);
      toast.current?.show({severity: 'success', summary: 'Succès', detail: 'Email vérifié avec succès'});
      navigate('/dashboard'); // Or wherever you want to redirect after successful verification
    } catch (error) {
      console.error('Email verification failed', error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Code PIN invalide'});
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const { name, value } = e.target;
    setter(value);
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="raison_social" className="block text-sm font-medium text-gray-700">
          Nom de l'entreprise
        </label>
        <input
          type="text"
          id="raison_social"
          name="raison_social"
          value={raison_social}
          onChange={(e) => handleInputChange(e, setRaisonSocial)}
          className={`mt-1 block w-full rounded-md border ${errors.raison_social ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
        />
        {errors.raison_social && <p className="mt-2 text-sm text-red-600">{errors.raison_social}</p>}
      </div>

      <div>
        <label htmlFor="responsible" className="block text-sm font-medium text-gray-700">
          Responsable
        </label>
        <input
          type="text"
          id="responsible"
          name="responsible"
          value={responsible}
          onChange={(e) => handleInputChange(e, setResponsible)}
          className={`mt-1 block w-full rounded-md border ${errors.responsible ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
        />
        {errors.responsible && <p className="mt-2 text-sm text-red-600">{errors.responsible}</p>}
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Adresse e-mail
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => handleInputChange(e, setEmail)}
          className={`mt-1 block w-full rounded-md border ${errors.email ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
        />
        {errors.email && <p className="mt-2 text-sm text-red-600">{errors.email}</p>}
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          Mot de passe
        </label>
        <div className="relative mt-1">
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            value={password}
            onChange={(e) => handleInputChange(e, setPassword)}
            className={`block w-full rounded-md border ${errors.password ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            {showPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
          </button>
        </div>
        {errors.password && <p className="mt-2 text-sm text-red-600">{errors.password}</p>}
      </div>

      <div>
        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
          Confirmation Mot de passe
        </label>
        <div className="relative mt-1">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => handleInputChange(e, setConfirmPassword)}
            className={`block w-full rounded-md border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            {showConfirmPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
          </button>
        </div>
        {errors.confirmPassword && <p className="mt-2 text-sm text-red-600">{errors.confirmPassword}</p>}
      </div>

      <div>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
        >
          {isLoading ? 'Création en cours...' : 'Créer un compte'}
        </button>
      </div>
    </form>
  );

  const renderVerificationForm = () => (
    <form onSubmit={handleVerification} className="space-y-6">
      <div>
        <label htmlFor="verificationPin" className="block text-sm font-medium text-gray-700">
          Code PIN
        </label>
        <input
          type="text"
          id="verificationPin"
          name="verificationPin"
          value={verificationPin}
          onChange={(e) => setVerificationPin(e.target.value)}
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500"
          maxLength={6}
        />
      </div>
      <button
        type="submit"
        disabled={isLoading}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
      >
        {isLoading ? 'Vérification...' : 'Vérifier'}
      </button>
    </form>
  );

  return (
    <div className="flex min-h-screen">
      <Toast ref={toast} />
      {/* Pattern Side */}
      <div className="hidden lg:flex lg:w-1/2 bg-teal-600 relative overflow-hidden">
        <div className="absolute inset-0" style={{
          backgroundImage: "url('/pattern.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }} />
      </div>

      {/* Signup Form Side */}
      <div className="w-full lg:w-1/2 flex flex-col justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-md w-full mx-auto">
          <div className="mb-6">
          <img className="w-[50%]  mx-auto text-teal-600" src='/dabablane-logo.png' />
          </div>
          
          <h2 className="text-2xl font-bold mb-8 text-gray-900">
            {showVerification ? 'Vérifiez votre email' : 'Création du compte en moins d\'une minute !'}
          </h2>
          
          {showVerification ? renderVerificationForm() : renderForm()}

          {!showVerification && (
            <>
              <div className="mt-4 text-center">
                <a href="/forgot-password" className="text-sm text-teal-600 hover:text-teal-500">
                  Mot de passe oublié ?
                </a>
              </div>

              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Ou</span>
                  </div>
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    onClick={() => navigate('/login')}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-coral-500 hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coral-500"
                  >
                    Se connecter
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}