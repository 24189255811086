import React, { useState, useEffect } from 'react';
import { Star, BarChart2, TrendingUp, Calendar, Users, Clock, CreditCard, AlertTriangle } from 'lucide-react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend, CartesianGrid } from 'recharts';
import { Dropdown } from 'primereact/dropdown';
import api from '../utils/api';
import { formatCurrency } from '../utils/formatters';

export default function DashboardContent() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState({
    revenue: {
      total: 0,
      byCategory: [],
      monthly: []
    },
    reservations: {
      total: 0,
      pending: 0,
      confirmed: 0, 
      cancelled: 0,
      byTimeSlot: [],
      monthly: []
    },
    occupancy: {
      rate: 0,
      byBlane: []
    },
    blanes: {
      total: 0,
      active: 0,
      expired: 0,
      expiringSoon:[],
      byCategory: [],
      topPerforming: []
    },
    customers: {
      total: 0,
      new: 0,
      returning: 0
    }
  });

  const [timeFilter, setTimeFilter] = useState('month');
  
  const timeOptions = [
    { label: 'Aujourd\'hui', value: 'today' },
    { label: 'Cette semaine', value: 'week' },
    { label: 'Ce mois', value: 'month' },
    { label: 'Cette année', value: 'year' },
    { label: 'Tout', value: 'all' }
  ];

  useEffect(() => {
    fetchDashboardData();
  }, [timeFilter]);

  const fetchDashboardData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await api.get('/merchant/dashboard', {
        params: {
          period: timeFilter
        }
      });
      
      setDashboardData(response.data);
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
      setError('Failed to load dashboard data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Dashboard</h1>
        <div className="flex space-x-4">
          <Dropdown
            value={timeFilter}
            options={timeOptions}
            onChange={(e) => setTimeFilter(e.value)}
            placeholder="Période"
            className="w-48"
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-teal-600"></div>
        </div>
      ) : error ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      ) : (
        <>
          {/* Key Metrics */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <MetricCard 
              title="CA Total" 
              value={formatCurrency(dashboardData.revenue.total)} 
              icon={<TrendingUp className="h-5 w-5 text-teal-600" />}
            />
            <MetricCard 
              title="Réservations" 
              value={dashboardData.reservations.total.toLocaleString()} 
              icon={<Calendar className="h-5 w-5 text-teal-600" />}
            />
            <MetricCard 
              title="Taux d'occupation" 
              value={`${dashboardData.occupancy.rate}%`} 
              icon={<Users className="h-5 w-5 text-teal-600" />}
            />
            <MetricCard 
              title="Blanes actifs" 
              value={dashboardData.blanes.active.toLocaleString()} 
              icon={<Star className="h-5 w-5 text-teal-600" />}
            />
          </div>

          {/* Reservation Stats */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">État des réservations</h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={[
                        { name: 'Confirmées', value: dashboardData.reservations.confirmed },
                        { name: 'En attente', value: dashboardData.reservations.pending },
                        { name: 'Annulées', value: dashboardData.reservations.cancelled },
                      ]}
                      cx="50%"
                      cy="50%"
                      labelLine={true}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={({name, percent}) => `${name}: ${(percent * 100).toFixed(0)}%`}
                    >
                      {[0, 1, 2].map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => value.toLocaleString()} />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">Réservations par créneau horaire</h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={dashboardData.reservations.byTimeSlot}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timeSlot" />
                    <YAxis />
                    <Tooltip formatter={(value) => value.toLocaleString()} />
                    <Bar dataKey="count" fill="#0D9488" name="Réservations" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          
          {/* Revenue Charts */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">CA Mensuel</h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={dashboardData.revenue.monthly}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip formatter={(value) => formatCurrency(value)} />
                    <Legend />
                    <Line 
                      type="monotone" 
                      dataKey="amount" 
                      stroke="#0D9488" 
                      name="Chiffre d'affaires" 
                      activeDot={{ r: 8 }} 
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">CA par catégorie</h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={dashboardData.revenue.byCategory}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="category" />
                    <YAxis />
                    <Tooltip formatter={(value) => formatCurrency(value)} />
                    <Bar dataKey="amount" fill="#8884d8" name="Chiffre d'affaires" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          
          {/* Blane Performance */}
          <div className="grid grid-cols-1 mt-6">
            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">Performance des blanes</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Titre
                      </th>
                      <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Catégorie
                      </th>
                      <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Prix réduit
                      </th>
                      <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Réservations
                      </th>
                      <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        CA généré
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData.blanes.topPerforming.map((blane, index) => (
                      <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="py-2 px-4 border-b border-gray-200">{blane.title}</td>
                        <td className="py-2 px-4 border-b border-gray-200">{blane.category}</td>
                        <td className="py-2 px-4 border-b border-gray-200">{formatCurrency(blane.reducedPrice)}</td>
                        <td className="py-2 px-4 border-b border-gray-200">{blane.reservations}</td>
                        <td className="py-2 px-4 border-b border-gray-200">{formatCurrency(blane.revenue)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
          {/* Expiring Blanes Alert */}
          {dashboardData.blanes.expiringSoon && dashboardData.blanes.expiringSoon.length > 0 && (
            <div className="mt-6 bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertTriangle className="h-5 w-5 text-yellow-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Attention: Blanes expirant bientôt
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <ul className="list-disc pl-5 space-y-1">
                      {dashboardData.blanes.expiringSoon.map((blane, index) => (
                        <li key={index}>
                          {blane.title} - Expire le {new Date(blane.endDate).toLocaleDateString()}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function MetricCard({ title, value, icon }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-start">
        <h3 className="text-sm text-gray-500">{title}</h3>
        {icon}
      </div>
      <p className="text-2xl font-bold mt-2">{value}</p>
    </div>
  );
}