import React, { useState, useRef } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { Toast } from 'primereact/toast';
import { useAuth } from '../contexts/AuthContext';

export default function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const { login } = useAuth();

  const validateForm = () => {
    const newErrors: { email?: string; password?: string } = {};
    if (!email.trim()) newErrors.email = 'L\'adresse e-mail est requise';
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'L\'adresse e-mail est invalide';
    if (!password) newErrors.password = 'Le mot de passe est requis';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await api.post('/signin/merchant', { email, password });
      const { token, merchant } = response.data;
      
      // Set the token for future API calls
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Use the login function from AuthContext to update the global auth state
      login(token, merchant);
      
      toast.current?.show({severity: 'success', summary: 'Succès', detail: 'Connexion réussie'});
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed', error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Identifiants invalides'});
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const { name, value } = e.target;
    setter(value);
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  return (
    <div className="flex min-h-screen">
      <Toast ref={toast} />
      {/* Pattern Side */}
      <div className="hidden lg:flex lg:w-1/2 bg-teal-600 relative overflow-hidden">
        <div className="absolute inset-0" style={{
          backgroundImage: "url('/pattern.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }} />
      </div>

      {/* Login Form Side */}
      <div className="w-full lg:w-1/2 flex flex-col justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-md w-full mx-auto">
          <div className="mb-6">
                    <img className="w-[50%]  mx-auto text-teal-600" src='/dabablane-logo.png' />

          </div>
          
          <h2 className="text-2xl font-bold mb-8 text-gray-900">
            Connectez-vous en utilisant votre login et mot de passe
          </h2>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Adresse e-mail / Téléphone
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => handleInputChange(e, setEmail)}
                className={`mt-1 block w-full rounded-md border ${errors.email ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
                placeholder="marchand@daba-blane.com"
              />
              {errors.email && <p className="mt-2 text-sm text-red-600">{errors.email}</p>}
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Mot de passe
              </label>
              <div className="relative mt-1">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => handleInputChange(e, setPassword)}
                  className={`block w-full rounded-md border ${errors.password ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  {showPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
                </button>
              </div>
              {errors.password && <p className="mt-2 text-sm text-red-600">{errors.password}</p>}
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
              >
                {isLoading ? 'Connexion en cours...' : 'Connexion'}
              </button>
            </div>
          </form>

          <div className="mt-4 text-center">
            <a href="/forgot-password" className="text-sm text-teal-600 hover:text-teal-500">
              Mot de passe oublié ?
            </a>
          </div>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Ou</span>
              </div>
            </div>

            <div className="mt-6">
              <button
                type="button"
                onClick={() => navigate('/signup')}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-coral-500 hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coral-500"
              >
                Créer un compte
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}