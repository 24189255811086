import React from 'react';
import { BarChart2, Zap, FileText, Settings,ShoppingBag } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import config from '../config/config';

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
    const { user } = useAuth();

  const menuItems = [
    { icon: BarChart2, text: 'Dashboard', path: '/dashboard' },
    { icon: Zap, text: 'Offres', path: '/offres' },
    { icon: ShoppingBag, text: 'Réservations', path: '/reservations' },
    { icon: FileText, text: 'Facturations', path: '/facturations' },
    { icon: Settings, text: 'Paramètres', path: '/parametres' },
  ];

  return (
    <aside className="w-64 bg-white border-r border-gray-200">
      <div className="p-6">
        <div className="flex items-center mb-8">
          <img className="w-full  mr-2 text-teal-600" src='/dabablane-logo.png' />
        </div>
        
        <nav>
          {menuItems.map((item, index) => (
            <a
              key={index}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(item.path);
              }}
              className={`flex items-center px-4 py-3 mb-2 rounded-lg ${
                location.pathname === item.path
                  ? 'bg-teal-600 text-white' 
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <item.icon className="w-5 h-5 mr-3" />
              {item.text}
            </a>
          ))}
        </nav>
      </div>
      
      <div className="absolute bottom-4 left-4 flex items-center">

        <img
          src={user.avatar ? `${config.endpoints.imgAvatar}/${user?.avatar}` : `/store-placeholder.png`}
          alt="Profile"
          className="w-10 h-10 rounded-full mr-3"
        />
        <a 
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate('/profile');
          }}
          className={` ${
                location.pathname ==='/profile'
                  ? 'bg-teal-600 text-white' 
                  : 'text-gray-600 hover:bg-gray-100'
              }   px-4 py-3 mb-2 rounded-lg font-medium`}>{user.raison_social}</a>
      </div>
    </aside>
  );
}