import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { Calendar, Clock, User, Phone, Mail, Package, MapPin, CreditCard, Users } from 'lucide-react';
import QRCode from 'react-qr-code';
import api from '../utils/api';

interface Reservation {
  id: string;
  code: string;
  date: string;
  time?: string;
  status: string;
  service: {
    title: string;
    type: string;
  };
  customer: {
    name: string;
    email: string;
    phone: string;
  };
  persons: number;
  price: number;
  created_at: string;
}

interface ReservationDetailModalProps {
  visible: boolean;
  onHide: () => void;
  reservation: Reservation;
  onStatusUpdate: (status: string) => Promise<void>;
  loading: boolean;
}

interface ReservationDetails {
  id: string;
  code: string;
  date: string;
  datetime: string;
  status: string;
  customer: {
    id: string;
    name: string;
    email: string;
    phone: string;
  };
  service: {
    id: string;
    title: string;
    description: string;
    type: string;
    pricePerPerson: number;
    originalPrice: number;
    image: string;
  };
  persons: number;
  price: number;
  created_at: string;
}

export default function ReservationDetailModal({ 
  visible, 
  onHide, 
  reservation, 
  onStatusUpdate,
  loading
}: ReservationDetailModalProps) {
  const [detailedReservation, setDetailedReservation] = useState<ReservationDetails | null>(null);
  const [detailsLoading, setDetailsLoading] = useState(true);

  useEffect(() => {
    if (visible && reservation) {
      fetchReservationDetails();
    }
  }, [visible, reservation]);

  const fetchReservationDetails = async () => {
    setDetailsLoading(true);
    try {
      const response = await api.get(`/merchant/reservations/${reservation.id}`);
      setDetailedReservation(response.data.reservation);
    } catch (error) {
      console.error('Failed to fetch reservation details:', error);
    } finally {
      setDetailsLoading(false);
    }
  };

  const confirmStatusUpdate = (status: string, title: string, message: string) => {
    confirmDialog({
      message,
      header: title,
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: status === 'cancelled' ? 'p-button-danger bg-red mx-5' : 'p-button-success mx-5',
      accept: () => onStatusUpdate(status)
    });
  };

  const getStatusSeverity = (status: string) => {
    switch (status.toLowerCase()) {
      case 'confirmed':
        return 'success';
      case 'pending':
        return 'warning';
      case 'cancelled':
        return 'danger';
      case 'completed':
        return 'info';
      default:
        return 'secondary';
    }
  };

  const getStatusLabel = (status: string) => {
    switch (status.toLowerCase()) {
      case 'confirmed':
        return 'Confirmée';
      case 'pending':
        return 'En attente';
      case 'cancelled':
        return 'Annulée';
      case 'completed':
        return 'Complétée';
      default:
        return status;
    }
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('fr-MA', { style: 'currency', currency: 'MAD' }).format(value);
  };

  const renderActionButtons = () => {
    const status = detailedReservation?.status.toLowerCase() || reservation.status.toLowerCase();
    
    if (status === 'cancelled') {
      return null;
    }
    
    return (
      <div className="flex gap-2 justify-end">
        {status === 'pending' && (
          <Button
            label="Confirmer"
            icon="pi pi-check"
            className="p-button-success p-button-raised p-button-text font-bold py-2 px-4"
            style={{ backgroundColor: '#10B981', color: 'white', borderRadius: '8px' }}
            loading={loading}
            onClick={() => confirmStatusUpdate(
              'confirmed', 
              'Confirmer la réservation', 
              'Êtes-vous sûr de vouloir confirmer cette réservation?'
            )}
          />
        )}
        {status !== 'completed' && (
          <Button
            label="Terminer"
            icon="pi pi-check-circle"
            className="p-button-info p-button-raised p-button-text font-bold py-2 px-4"
            style={{ backgroundColor: '#3B82F6', color: 'white', borderRadius: '8px' }}
            loading={loading}
            onClick={() => confirmStatusUpdate(
              'completed', 
              'Terminer la réservation', 
              'Êtes-vous sûr de vouloir marquer cette réservation comme terminée?'
            )}
          />
        )}
        {status !== 'cancelled' && (
          <Button
            label="Annuler"
            icon="pi pi-times"
            className="p-button-danger p-button-raised p-button-text font-bold py-2 px-4"
            style={{ backgroundColor: '#EF4444', color: 'white', borderRadius: '8px' }}
            loading={loading}
            onClick={() => confirmStatusUpdate(
              'cancelled', 
              'Annuler la réservation', 
              'Êtes-vous sûr de vouloir annuler cette réservation?'
            )}
          />
        )}
      </div>
    );
  };

  const footerContent = (
    <div className="flex justify-between items-center ">
      <Button 
        label="Fermer" 
        icon="pi pi-times" 
        onClick={onHide} 
        className="p-button-text p-button-secondary font-bold"
        style={{ color: '#6B7280' }}
      />
      {renderActionButtons()}
    </div>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: '50vw', minWidth: '350px' }}
      header="Détails de la réservation"
      modal
      className="p-fluid reservation-detail-dialog"
      
      footer={footerContent}
      onHide={onHide}
      contentClassName="p-4"
      headerClassName="bg-teal-600 text-white"

    >
      <ConfirmDialog />
      
      {detailsLoading ? (
        <div className="flex justify-center items-center h-64">
          <i className="pi pi-spin pi-spinner text-4xl"></i>
        </div>
      ) : detailedReservation ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="col-span-1 md:col-span-2 flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
            <div>
              <h2 className="text-2xl font-bold mb-2">{detailedReservation.service.title}</h2>
              <Tag 
                value={getStatusLabel(detailedReservation.status)} 
                severity={getStatusSeverity(detailedReservation.status)} 
                className="mr-2"
              />
              <span className="text-gray-500">Code: {detailedReservation.code}</span>
            </div>
            <div className="mt-4 md:mt-0">
              <div className="w-24 h-24 mx-auto md:mx-0">
                <QRCode
                  size={96}
                  value={detailedReservation.code}
                  viewBox={`0 0 96 96`}
                />
              </div>
            </div>
          </div>

          <div className="col-span-1 md:col-span-2">
            <Divider align="left">
              <div className="inline-flex align-items-center">
                <Calendar className="mr-2" size={16} />
                <b>Informations sur la réservation</b>
              </div>
            </Divider>
          </div>

          <div className="col-span-1 bg-gray-50 rounded p-4">
            <div className="flex items-center mb-3">
              <Calendar className="mr-2 text-teal-600" size={18} />
              <span className="font-medium">Date:</span>
              <span className="ml-2">{detailedReservation.date}</span>
            </div>
            {detailedReservation.datetime.includes('à') && (
              <div className="flex items-center mb-3">
                <Clock className="mr-2 text-teal-600" size={18} />
                <span className="font-medium">Heure:</span>
                <span className="ml-2">{detailedReservation.datetime.split('à')[1].trim()}</span>
              </div>
            )}
            <div className="flex items-center mb-3">
              <Users className="mr-2 text-teal-600" size={18} />
              <span className="font-medium">Personnes:</span>
              <span className="ml-2">{detailedReservation.persons}</span>
            </div>
            <div className="flex items-center">
              <CreditCard className="mr-2 text-teal-600" size={18} />
              <span className="font-medium">Prix total:</span>
              <span className="ml-2">{formatCurrency(detailedReservation.price)}</span>
            </div>
          </div>

          <div className="col-span-1 bg-gray-50 rounded p-4">
            <div className="flex items-center mb-3">
              <User className="mr-2 text-teal-600" size={18} />
              <span className="font-medium">Client:</span>
              <span className="ml-2">{detailedReservation.customer.name}</span>
            </div>
            <div className="flex items-center mb-3">
              <Phone className="mr-2 text-teal-600" size={18} />
              <span className="font-medium">Téléphone:</span>
              <a href={`tel:${detailedReservation.customer.phone}`} className="ml-2 text-blue-600">
                {detailedReservation.customer.phone}
              </a>
            </div>
            <div className="flex items-center">
              <Mail className="mr-2 text-teal-600" size={18} />
              <span className="font-medium">Email:</span>
              <a href={`mailto:${detailedReservation.customer.email}`} className="ml-2 text-blue-600">
                {detailedReservation.customer.email}
              </a>
            </div>
          </div>

          <div className="col-span-1 md:col-span-2">
            <Divider align="left">
              <div className="inline-flex align-items-center">
                <Package className="mr-2" size={16} />
                <b>Détails du service</b>
              </div>
            </Divider>
          </div>

          <div className="col-span-1 md:col-span-1">
            {detailedReservation.service.image && (
              <img 
                src={detailedReservation.service.image} 
                alt={detailedReservation.service.title}
                className="w-full h-48 object-cover rounded mb-4"
              />
            )}
            <h3 className="text-lg font-semibold mb-2">{detailedReservation.service.title}</h3>
            <div className="flex items-center mb-3">
              <Package className="mr-2 text-teal-600" size={18} />
              <span className="font-medium">Type:</span>
              <span className="ml-2">{detailedReservation.service.type}</span>
            </div>
            <div className="flex items-center mb-3">
              <CreditCard className="mr-2 text-teal-600" size={18} />
              <span className="font-medium">Prix par personne:</span>
              <span className="ml-2">{formatCurrency(detailedReservation.service.pricePerPerson)}</span>
            </div>
            {detailedReservation.service.originalPrice > detailedReservation.service.pricePerPerson && (
              <div className="flex items-center mb-3">
                <CreditCard className="mr-2 text-teal-600" size={18} />
                <span className="font-medium">Prix original:</span>
                <span className="ml-2 line-through text-gray-500">
                  {formatCurrency(detailedReservation.service.originalPrice)}
                </span>
              </div>
            )}
          </div>

          <div className="col-span-1 md:col-span-1">
            <h3 className="text-lg font-semibold mb-2">Description</h3>
            <p className="text-gray-700">{detailedReservation.service.description}</p>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-64">
          <p className="text-gray-500">Aucun détail trouvé pour cette réservation</p>
        </div>
      )}
    </Dialog>
  );
}