import DashboardLayout from '../components/DashboardLayout';
import OffersContent from '../components/OffersContent';



export default function Offres() {
  return (
    <DashboardLayout>
        <OffersContent />
    </DashboardLayout>
  );
}