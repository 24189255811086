import React, { useState, useEffect, useRef } from 'react';
import { Crown, Zap, Search, PackageOpen } from 'lucide-react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Paginator } from 'primereact/paginator';
import OfferTile from './OfferTile';
import NewBlaneModal from './NewBlaneModal';
import api from '../utils/api';

interface Offer {
  id: string;
  title: string;
  image_url: string;
  merchant: {
    name: string;
  };
  original_price: number;
  reduced_price: number;
  modality: string;
  reservation_type: string;
  condition: "product" | "service" | "delivery";
  rating: number;
  review_count: number;
  end_date: string;
  distance: string;
}

interface PaginatedResponse {
  data: Offer[];
  current_page: number;
  per_page: number;
  total: number;
}

const offerStatuses = [{label : 'Tout' ,key : '', },{label : 'Actives' ,key : 'active', },{label : 'En attente' ,key : 'pending' },{label : 'Expirées' ,key : 'expired' },{label : 'Archivées' ,key : 'archived' }];

export default function OffresPage() {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeStatus, setActiveStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [maxTileWidth, setMaxTileWidth] = useState(0);

  const tileRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [filters, setFilters] = useState({
    search: '',
    category: '',
    min_price: '',
    max_price: '',
    start_date: null as Date | null,
    end_date: null as Date | null,
  });

  const categories = [
    { label: 'All Categories', value: '' },
    { label: 'Restaurant', value: 'restaurant' },
    { label: 'Beauty', value: 'beauty' },
    { label: 'Leisure', value: 'leisure' },
  ];

  useEffect(() => {
    fetchOffers();
  }, [activeStatus, first, rows, filters]);

  useEffect(() => {
    if (!loading && offers.length > 0) {
      const maxWidth = Math.max(...tileRefs.current.map(ref => ref?.offsetWidth || 0));
      setMaxTileWidth(maxWidth);
    }
  }, [loading, offers]);

  const fetchOffers = async () => {
    setLoading(true);
    try {
      const response = await api.get<PaginatedResponse>('/merchant/blanes/list', {
        params: {
          status: activeStatus.toLowerCase(),
          page: first / rows + 1,
          per_page: rows,
          search: filters.search,
          category: filters.category,
          min_price: filters.min_price,
          max_price: filters.max_price,
          start_date: filters.start_date?.toISOString().split('T')[0],
          end_date: filters.end_date?.toISOString().split('T')[0],
        },
      });
      setOffers(response.data.data);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error('Failed to fetch offers:', error);
      setOffers([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event: { first: number; rows: number }) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleFilterChange = (name: string, value: string | Date | null) => {
    setFilters(prev => ({ ...prev, [name]: value }));
    setFirst(0); 
  };

  const itemTemplate = (offer: Offer, index: number) => {
    return (
      <div 
        ref={el => tileRefs.current[index] = el} 
        style={{ width: maxTileWidth > 0 ? `${maxTileWidth}px` : 'auto' }}
      >
        <OfferTile
          key={offer.id}
          image={offer.image_url}
          title={offer.title}
          merchant={offer.merchant}
          rating={offer.rating || 0}
          review_count={offer.review_count}
          original_price={offer.original_price}
          reduced_price={offer.reduced_price}
          end_date={offer.end_date}
          distance={offer.distance}
          id={offer.id}
          persons_per_session={0}
          condition={offer.condition}
          modality={offer.modality}
        />
      </div>
    );
  };

  const skeletonTemplate = () => {
    return (
      <div className="bg-white rounded-lg shadow overflow-hidden flex-shrink-0 h-32 w-full">
        <div className="h-full bg-gray-200 animate-pulse"></div>
      </div>
    );
  };

  const addingOffer = (formData: any) => {
    fetchOffers();
  };

  return (
    <div className="p-6 flex flex-col min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Mes offres</h1>
        <a href="/abonement" className="flex items-center px-4 py-2 bg-teal-600 text-white rounded-md">
        <svg className="w-4 h-4 mr-2 text-[#FFB743]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.12755 16.2327L0.758799 7.33624C0.657549 6.67924 1.40605 6.23074 1.9378 6.62974L6.2023 9.82774C6.31315 9.91078 6.43975 9.97036 6.57439 10.0028C6.70903 10.0353 6.84888 10.04 6.98539 10.0166C7.12191 9.99326 7.25223 9.94231 7.36839 9.86689C7.48456 9.79147 7.58414 9.69317 7.66105 9.57799L11.2115 4.25299C11.5865 3.69049 12.413 3.69049 12.788 4.25299L16.3385 9.57799C16.4155 9.69317 16.515 9.79147 16.6312 9.86689C16.7474 9.94231 16.8777 9.99326 17.0142 10.0166C17.1507 10.04 17.2906 10.0353 17.4252 10.0028C17.5598 9.97036 17.6865 9.91078 17.7973 9.82774L22.0618 6.62974C22.5943 6.23074 23.3421 6.67924 23.2408 7.33624L21.872 16.2327H2.12755ZM20.8423 21.1692H3.1573C3.02207 21.1692 2.88817 21.1426 2.76323 21.0909C2.6383 21.0391 2.52478 20.9633 2.42916 20.8676C2.23604 20.6745 2.12755 20.4126 2.12755 20.1395V17.8782H21.872V20.1395C21.872 20.708 21.4108 21.1692 20.8423 21.1692Z" fill="#FFB743"/>
          </svg>
          Activer PREMIUM
        </a>
      </div>

      <div className="flex space-x-2 mb-6 flex-wrap">
        {offerStatuses.map((status) => (
          <button
            key={status.key}
            className={`px-4 py-2 rounded-full text-sm font-medium mb-2 ${
              activeStatus === status.key
                ? 'bg-teal-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
            onClick={() => setActiveStatus(status.key)}
          >
            {status.label}
          </button>
        ))}
      </div>

      <div className="flex-grow">
        {loading ? (
          <div className="flex flex-wrap gap-6 justify-start">
            {Array.from({ length: rows }).map((_, index) => (
              <div key={index} className="w-full sm:w-auto">{skeletonTemplate()}</div>
            ))}
          </div>
        ) : offers.length > 0 ? (
          <div className="flex flex-wrap gap-6 justify-start">
            {offers.map((offer, index) => (
              <div key={offer.id} className="flex-shrink-0">
                {itemTemplate(offer, index)}
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-64">
            <PackageOpen className="w-16 h-16 text-gray-400 mb-4" />
            <p className="text-xl font-semibold text-gray-600">Aucune offre trouvée</p>
            <p className="text-gray-500 mt-2">Essayez d'ajuster vos filtres ou créez une nouvelle offre</p>
          </div>
        )}
      </div>

      {totalRecords > 0 && (
        <div className="mt-6">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
            className="bg-white shadow-md rounded-lg p-4"
          />
        </div>
      )}

      <button
        className="fixed bottom-8 right-8 bg-teal-600 text-white rounded-2xl p-4 shadow-lg hover:bg-teal-700 transition-colors flex flex-col items-center"
        onClick={() => setIsModalOpen(true)}
      >
        <svg className="w-6 h-6 mb-1"  width="26" height="40" viewBox="0 0 26 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.9687 19.4475L18.437 7.33291L21.1426 0H6.21055L0.394531 26.1031H9.20824C8.85466 27.7377 8.50109 29.3775 8.15264 31.0121C7.52236 33.9453 6.88695 36.8835 6.25667 39.8167V40C9.23386 36.8377 12.2059 33.6805 15.1831 30.5181C18.6573 26.8262 22.1316 23.1343 25.6058 19.4475H13.9687Z" fill="white"/>
              </svg>

        <span className="text-xs">New BLANE</span>
      </button>
      {isModalOpen && <NewBlaneModal onSubmit={addingOffer} onClose={() => setIsModalOpen(false)} />}
    </div>
  );
}